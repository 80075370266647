<nz-modal
  [nzVisible]="showPluginManager"
  (nzVisibleChange)="toggleDialogChange.next($event)"
  [nzCloseIcon]="'close-circle'"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzWidth]="870"
  (nzOnCancel)="toggleDialogChange.next($event)"
>
  <ng-template #modalTitle>
    <div class="app-dialog-header">
      <div class="app-dialog-title">{{ 'PLUGINS_TEXT' | translate }}</div>
      {{ 'PLUGINS_SUB_TEXT' | translate }}
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="app-dialog-body">
      <div class="app-dialog-section">
        <div class="plugin-manager-wrapper">
          <div class="plugin-manager__list">
            @for (item of remotePlugins$ | async; track trackById($index, item)) {
              <div
                class="plugin-manager__list-item"
                [ngClass]="{
                  'plugin-manager__list-item--selected':
                    selectedPluginItem && selectedPluginItem.id === item.id,
                }"
                (click)="onSelectPlugin(item)"
              >
                <div class="plugin-manager__list-item-name">
                  {{ item.name }}
                  <!-- <div class="plugin-manager__list-item-version">
                    {{ item?.manifest?.version || item.version }}
                  </div> -->
                </div>
                <div class="plugin-manager__list-item-description">
                  {{ item.description }}
                </div>
              </div>
            }
            <a
              class="plugin-manager__list-item"
              href="https://www.npmjs.com/search?q=%22altair-graphql-plugin-%22"
              target="_blank"
            >
              Find more plugins on npm <app-icon name="external-link"></app-icon>
            </a>
          </div>
          <div class="plugin-manager__item-details">
            @if (selectedPluginItem) {
              <div class="plugin-manager__item-details-name">
                {{ selectedPluginItem.id }}
                <div
                  class="plugin-manager__item-details-unique-name"
                  [attr.title]="'PLUGIN_UNIQUE_NAME_TEXT' | translate"
                >
                  {{ selectedPluginItem.id }}
                </div>
              </div>
              <div class="plugin-manager__item-details-actions">
                @if (!isPluginInstalled(selectedPluginItem.id)) {
                  <button
                    class="btn btn--small"
                    (click)="onAddPlugin(selectedPluginItem.id)"
                  >
                    <app-icon name="plus-circle"></app-icon>
                    {{ 'PLUGIN_ADD_BUTTON' | translate }}
                  </button>
                }
                @if (isPluginInstalled(selectedPluginItem.id)) {
                  <button
                    class="btn btn--small btn--light btn--destructive"
                    (click)="onRemovePlugin(selectedPluginItem.id)"
                  >
                    <app-icon name="x-circle"></app-icon>
                    {{ 'PLUGIN_REMOVE_BUTTON' | translate }}
                  </button>
                }
                @if (shouldRestart) {
                  <button class="btn btn--small" (click)="onRestartApp()">
                    {{ 'RESTART_TEXT' | translate }}
                  </button>
                }
              </div>
              <div
                markdown
                class="plugin-manager__item-details-summary"
                [data]="selectedPluginItem.description"
              ></div>
            } @else {
              <div class="plugin-manager__item-details-empty">
                Select a plugin to view details
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="app-dialog-footer">
      <a
        href="https://altairgraphql.dev/docs/plugins/writing-plugin.html"
        target="_blank"
        class="btn left"
        >{{ 'PLUGIN_CREATE_BUTTON' | translate }}</a
      >
      <button
        class="btn btn--orimary right"
        (click)="toggleDialogChange.next(false)"
      >
        {{ 'SAVE_BUTTON' | translate }}
      </button>
    </div>
  </ng-template>
</nz-modal>
